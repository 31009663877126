import Text from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import { afterDate } from "../../../lib/utils";
import styled from "styled-components";

const DatesBox = styled(Box)`
    border-radius: 20px;
`;

export const DateRow = ({
    date,
    text,
    dateColor = "black",
    textColor = "black",
    endDate = "",
    ...rest
}: {
    date: string;
    text: string;
    dateColor?: string;
    textColor?: string;
    endDate?: string;
}) => {
    const isExpired = endDate !== "" && afterDate(endDate);

    if (isExpired) return null;

    return (
        <Box display={["block", "block", "flex"]} alignItems="flex-start" mb={7} {...rest}>
            <Text as="p" color={dateColor} flex="none" width={[200, 200, 200, 300]} typeStyle="title02">
                {date}
            </Text>
            <Text
                as="p"
                flex="1 1 auto"
                ml={[0, 0, 4]}
                mt={1}
                color={textColor}
                typeStyle="text01"
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </Box>
    );
};

interface ImportantDateProps extends BoxProps {
    textColor?: string;
    backgroundColor?: string;
    termsLink?: string;
    termsColor?: string;
}

function ImportantDates({
    textColor = "black",
    backgroundColor = "white",
    termsLink,
    termsColor = "black",
    children,
    ...rest
}: ImportantDateProps) {
    return (
        <TwelveColumn {...rest}>
            <DatesBox
                bg={backgroundColor}
                gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 10"]}
                px={[5, 5, 8]}
                pt={6}
            >
                <Text as="h2" color={textColor} textTransform="uppercase" typeStyle="title03" py={[4, 6]} mb={4}>
                    Important Dates
                </Text>
                {children}
                {termsLink && (
                    <Box mx="auto" textAlign="center" pt={4} pb={7}>
                        <Text
                            as="a"
                            className="terms"
                            color={termsColor}
                            textDecoration="underline"
                            href={termsLink}
                            target="_blank"
                        >
                            Terms and Conditions
                        </Text>
                    </Box>
                )}
            </DatesBox>
        </TwelveColumn>
    );
}

export default ImportantDates;
