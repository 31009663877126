import React from "react";
import Text from "@onnit-js/ui/components/text";
import Box from "@onnit-js/ui/components/box/Box";
import colors from "./colors";
import {Img} from "@onnit-js/ui/components/image";
import config from "../../../../config/config";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FaChevronRight } from "react-icons/fa";

const articles = [
    {
        title: "How To Set Goals You'll Achieve",
        img: "2023-nyay-WPage-helpful-achieve_goals_article.png",
        url: "/academy/how-to-set-goals/",
        altText: "Man on mountain top",
    },
    {
        title: "Eric Leija’s Onnit Story",
        img: "2023-nyay-WPage-helpful-eric_leija_article.png",
        url: "/academy/eric-leija-onnit-story/",
        altText: "Eric Leija working out",
    },
    {
        title: "HydroCore Bag Training",
        img: "2023-nyay-WPage-helpful-hyrdocore_article.png",
        url: "/academy/hydrocore-bag-training-for-an-unshakeable-body/",
        altText: "Person using the hydrocore bag",
    },
    {
        title: "Which Alpha BRAIN® Is Right For Me?",
        img: "2023-nyay-WPage-helpful-alphabrain_for_me_article.png",
        url: "/academy/which-alpha-brain-is-right-for-me/\n",
        altText: "man adding Alpha BRAIN instant to water",
    },
    {
        title: "Meet The Shroom Tech® Family",
        img: "2023-nyay-WPage-helpful-shroom_article.png",
        url: "/academy/shroom-tech-family/",
        altText: "Bottle of ShroomTECH Sport",
    },
    {
        title: "The Steel Club: Benefits and Uses",
        img: "2023-nyay-WPage-helpful-steel_club_article.png",
        url: "/academy/the-steel-club/",
        altText: "Man using a steel club",
    },
];

interface Props {
    baseImgUrl: string;
}

const Articles = ({ baseImgUrl }: Props) => {
    return (
        <Box py={[4, 4, 4, 6]}>
            <Text
                as="h2"
                textTransform="uppercase"
                typeStyle="title03"
                py={[3, 4]}
                mb={4}
                textAlign="center"
            >
                Helpful Content
            </Text>
            <Box
                display={["block", "block", "grid"]}
                gridTemplateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
                px={[0, 0, 6]}
                maxWidth={1564}
                mx="auto"
            >
                {articles.map((article, index: number) => (
                    <Box
                        key={`article-${index}`}
                        as="a"
                        href={article.url}
                        bg={colors.bgGrey}
                        px={[4, 4, 6]}
                        py={4}
                        my={[3, 3, 0]}
                        style={{ textDecoration: "none" }}
                        display={["flex", "flex", "block"]}
                        alignItems="center"
                    >
                        <Box mb={[0, 0, 4]} flex="0 0 90px">
                            <Img
                                placeholder={[460, 460]}
                                src={`${baseImgUrl}/${article.img}${config.IMG_PARAMS}`}
                                alt={article.altText}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" width={1} mb={[0, 0, 4]}>
                            <Text px={[2, 2, 0]} as="p" typeStyle="title04">
                                {article.title}
                            </Text>
                            <Icon ml="auto" width={18} icon={FaChevronRight} />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Articles;
