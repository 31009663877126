import { createRoot } from 'react-dom/client';
import React from 'react';

import { beforeDate, todayInDateRange } from '../../lib/utils';
import Voting from './Voting';
import Hiatus from './Hiatus';
import Active from './Active';

const PageComponent = () => {
	if (beforeDate('2024-04-15 23:59:59')) {
		return <Active />;
	}
	if (todayInDateRange('2024-04-16 00:00:00', '2024-04-20 23:59:59')) {
		return <Voting />;
	}
	return <Hiatus />;
};

const root = createRoot(document.getElementById('page-content')!);
root.render(<PageComponent />);
