import React from "react";
import colors from "../2024/colors";
import {Img} from "@onnit-js/ui/components/image";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import Text, {Sup} from "@onnit-js/ui/components/text";
import FlashBanner from "@onnit-js/ui/components/module/FlashBanner";

interface IAlphaBrainPreWorkoutFlashBanner extends BoxProps {
    IMGIX_URL: string;
    IMGIX_PARAMS: string;
    IMGIX_SVG_PARAMS: string;
}

const AlphaBrainPreWorkoutFlashBanner: React.FC<React.PropsWithChildren<React.PropsWithChildren<IAlphaBrainPreWorkoutFlashBanner>>> = ({
    IMGIX_URL,
    IMGIX_PARAMS,
    IMGIX_SVG_PARAMS,
    ...rest
}: IAlphaBrainPreWorkoutFlashBanner) => {
    return (
        <FlashBanner
            bg={colors.orange}
            url="/alpha-brain-pre-workout/"
            headline={<Img src={`${IMGIX_URL}/abpw-flash-title-01.svg${IMGIX_SVG_PARAMS}`} alt="Try Alpha BRAIN Pre Workout for FREE" />}
            image={
                <Box maxWidth={["initial", "initial", "90px"]}>
                    <Img src={`${IMGIX_URL}/ABPW-COMP-72dpi.png${IMGIX_PARAMS}&w=160`} alt="Container of Alpha BRAIN Pre Workout" />
                </Box>
            }
            button={
                <Button
                    aria-label="shop now"
                    color={colors.black}
                    raised={false}
                    textColor="white"
                    size={["icon", "icon", "icon", "large"]}
                >
                    <span aria-hidden="true">Shop now</span>
                </Button>
            }
            {...rest}
        >
            <Text as="p" color="black" typeStyle="text03">
                No more “meh” workouts. Alpha BRAIN Pre-Workout charges up the mind and body, promoting mental 
                sharpness—as well as muscle endurance and power—while helping to reduce perception of effort.
                <Sup variant="dagger" />
            </Text>
        </FlashBanner>
    );
};

export default AlphaBrainPreWorkoutFlashBanner;
