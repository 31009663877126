import React from "react";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {BgImage, Img} from "@onnit-js/ui/components/image";

interface Props extends BoxProps {
    baseUrl: string;
    params: string;
    svgParams: string;
}

const NyayHeroBanner = ({ baseUrl, params, svgParams }: Props) => {
    return (
        <BgImage
            backgroundPosition="center top"
            backgroundSize="cover"
            src={`${baseUrl}/2024-100K-Wpage-header.png${params}`}
        >
            <TwelveColumn height={[80, 160, 224]} alignItems="center">
                <Box mx="auto" gridColumn={["1 / -1", "2 / span 10", "5 / span 4"]}>
                    <Box maxWidth={["200px", "224px", "500px"]}>
                        <h1 className="sr-only">New Year All You</h1>
                        <Img
                            aria-hidden="true"
                            src={`${baseUrl}/100K-Logo-2-Layer-Backed.svg${svgParams}`}
                            alt="New Year All You Logo."
                            width={229}
                            height={66}
                            lazyLoad={false}
                        />
                    </Box>
                </Box>
            </TwelveColumn>
        </BgImage>
    );
};

export default NyayHeroBanner;
