import React from "react";

/**
 * This component is for embedding entire forms built in Survey Monkey.
 */

interface Props {
    formId: string;
}

export const SurveyMonkeyFormEmbed = ({ formId }: Props) => {

    React.useEffect(() => {
        if (!window.SMCX) {
            const script = document.createElement("script");
            script.src = `https://widget.surveymonkey.com/collect/website/js/${formId}.js`;
            document.body.appendChild(script);
        }
    }, []);

    return <div id="smcx-sdk"></div>;
};