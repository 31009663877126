import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import colors from "./colors";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";

const BuildProgram = () => {
    return (
        <TwelveColumn py={6} px={4} bg={colors.bgGrey}>
            <Box display="flex" flexDirection="column" gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}>
                <Text as="h2" typeStyle="title01" mb={2}>
                    Build your own program
                </Text>
                <Text as="p" typeStyle="text02" mb={6}>
                    Combine any two O6 programs, up to four Onnit In 30 programs, or mix and match O6 with Onnit In 30 to 
                    make your training last 12 weeks. See the following options:
                </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}>
                <Text as="h3" typeStyle="text01" mb={[4, 2, 2]} fontSize={[3]} style={{ fontWeight: 900 }}>
                    Recommended Option
                </Text>
                <Text as="p" typeStyle="text02" mb={4}>
                    <span style={{ fontWeight: 900 }}>Weeks 1–6:</span> O6 Durability or O6 HydroCore
                </Text>
                <Text as="p" typeStyle="text02" mb={6}>
                    <span style={{ fontWeight: 900 }}>Weeks 7–12:</span> Any of the remaining O6 programs (O6 Bodyweight, Kettlebell, Steel Mace, Steel Club, or Barbell)
                </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}>
                <Text as="h3" typeStyle="text01" mb={[4, 2, 2]} fontSize={[3]} style={{ fontWeight: 900 }}>
                    Onnit In 30 Option
                </Text>
                <Text as="p" typeStyle="text02" mb={4}>
                    <span style={{ fontWeight: 900 }}>Weeks 1–6:</span> My Warmup, Your Workout, and Mobilized Strength
                </Text>
                <Text as="p" typeStyle="text02" mb={6}>
                    <span style={{ fontWeight: 900 }}>Weeks 7–12:</span> Kettlebell Athlete, and Move & Groove
                </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}>
                <Text as="h3" typeStyle="text01" mb={[4, 2, 2]} fontSize={[3]} style={{ fontWeight: 900 }}>
                    Onnit 6 and Onnit In 30 Option
                </Text>
                <Text as="p" typeStyle="text02" mb={4}>
                    <span style={{ fontWeight: 900 }}>Weeks 1–6:</span> Move & Groove, Mobilized Strength (Onnit In 30)
                </Text>
                <Text as="p" typeStyle="text02" mb={6}>
                    <span style={{ fontWeight: 900 }}>Weeks 7–12:</span> Your choice of Onnit 6 program
                </Text>
            </Box>
        </TwelveColumn>
    );
};

export default BuildProgram;